<template>
  <div>
    <chi-tiet-ho-so
      :data="data"
      :table-data="tableData"
    />
  </div>
</template>
<script>
import ChiTietHoSo from '@/components/ChiTietHoSo.vue'

export default {
  name: 'ChiTietTiepNhan',
  components: {
    ChiTietHoSo,
  },
  data() {
    return {
      data: {
        date: '21/03/2022 15:34:03',
        people: 'Nguyễn Văn A',
        name: 'Nguyễn Văn A',
        address: 'Ngõ 4, DUy Tân, Cầu Giấy, Hà Nội',
        email: 'TestDVC@gmail.com',
        phone: '09836091234',
        cmt: '001076511526',
      },
      tableData: [
        {
          id: 1,
          name: 'Tờ khái theo mẫu quy định',
          main: 1,
          copy: 0,
          photo: 0,
          file: [],
        },
        {
          id: 2,
          name: 'Văn bản ủy quyền cho cá nhân thực hiện thủ tục liên quan đến đăng ký doanh nghiệp. Văn bản ủy quyền này không bắt buộc phải công chứng. chứng nhận',
          main: 1,
          copy: 0,
          photo: 0,
          file: [],
        },
        {
          id: 3,
          name: 'Bản sao giấy tờ pháp lý của cá nhân được ủy quyền',
          main: 0,
          copy: 1,
          photo: 0,
          file: [],
        },
      ],
    }
  },
}
</script>
