<template>
  <b-modal
    ref="commonModal"
    :title="title"
    :size="size"
    button-size="sm"
    footer-class="format-footer"
    header-class="text-gray-black"
    cancel-variant="danger"
    :ok-title="$slots.default ? 'Lưu' : 'Xoá'"
    cancel-title="Đóng"
    no-close-on-backdrop
    @ok="onHandleOk"
    @hidden="resetForm"
  >
    <validation-observer
      ref="commonRules"
    >
      <slot />
    </validation-observer>
    <span v-if="!$slots.default">Bạn có chắc chắn muốn xóa không?</span>
    <template slot="modal-footer">
      <slot name="footer" />
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  data() {
    return {
    }
  },
  methods: {
    showModal() {
      this.$refs.commonModal.show()
    },
    hideModal() {
      this.$refs.commonModal.hide()
      this.resetForm()
    },
    onHandleOk(e) {
      e.preventDefault()
      this.$refs.commonRules.validate().then(success => {
        if (success) {
          this.$emit('handle-ok')
        }
      })
    },
    checkValidate() {
      this.$refs.commonRules.validate().then(success => {
        if (success) {
          this.$emit('handle-ok')
        } else {
          const first = Object.entries(this.$refs.commonRules.errors).find(item => item[1].length > 0)
          this.$emit('handle-focus', first)
        }
      })
    },
    resetForm() {
      this.$emit('reset-form')
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep .modal-content{
   width:110% !important;
}
</style>
