<template>
  <div>
    <ve-table
      :border-y="borderColumn"
      style="width:100%"
      :columns="columns"
      :table-data="tableData"
      :row-key-field-name="rowKey"
      :checkbox-option="checkboxOption"
    />
    <div
      v-if="tableData.length <= 0"
      class="empty-data"
    >
      Không có dữ liệu
    </div>
    <div
      v-if="pagination"
      class="d-flex align-items-center justify-content-between mt-2"
    >
      <div>
        <label>Hiển thị</label>
        <v-select
          v-model="paginate.pageSize"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
          @input="pageChange"
        />
        <label>mục</label>
      </div>
      <b-pagination
        v-model="paginate.currentPage"
        :total-rows="totalRows"
        :per-page="paginate.pageSize"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @input="pageChange"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </div>
  </div>
</template>

<script>
import {
  BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { PER_PAGE_OPTIONS } from '@/constants/constants'

export default {
  name: 'AppTable',
  components: {
    BPagination,
    vSelect,
  },
  props: {
    columns: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    totalRows: {
      type: Number,
      default: () => 0,
    },
    rowKey: {
      type: String,
      default: () => 'id',
    },
    borderColumn: {
      type: Boolean,
      default: true,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      perPageOptions: PER_PAGE_OPTIONS,
      paginate: {
        pageSize: 20,
        currentPage: 1,
      },
      checkboxOption: {
        selectedRowChange: ({ selectedRowKeys }) => {
          this.$emit('row-selected', selectedRowKeys)
        },
        selectedAllChange: ({ selectedRowKeys }) => {
          this.$emit('row-selected', selectedRowKeys)
        },
      },
    }
  },
  methods: {
    pageChange() {
      this.$emit('page-change', this.paginate)
    },
  },
}
</script>

<style scoped>
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}
</style>
