<template>
  <b-card
    no-body
    class="mb-0"
  >
    <div class="card-body">
      <div class="d-sm-flex justify-content-end mb-1">
        <button
          class="compact ui btn-primary button"
        >
          Xem luồng xử lý
        </button>
      </div>
      <app-table
        class="mb-1"
        :columns="columns"
        :table-data="tableData"
      />
      <div class="d-sm-flex justify-content-end">
        <button
          class="compact ui btn-primary button mr-1"
        >
          In chi tiết tiến độ xử lý
        </button>
        <button
          class="compact ui btn-danger button"
        >
          Đóng
        </button>
      </div>
    </div>
  </b-card>
</template>
<script>
import {
  BCard,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import AppTable from '@/components/AppTable.vue'

export default {
  name: 'ChiTietHoSo',
  components: {
    BCard,
    AppTable,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      tableData: [
        {
          role: 'Bộ phận một cửa',
          job: 'Tiếp nhận và phân loại',
        },
        {
          role: 'Văn phòng cục PTDN',
          job: 'Tiếp nhận',
        },
        {
          role: 'Bộ phận một cửa',
          job: 'Tiếp nhận và phân loại',
        },
        {
          role: 'Lãnh đạo cục PTDN',
          job: 'Lãnh đạo vụ phân công',
        },
        {
          role: 'Lãnh đạo phòng THCS',
          job: 'Lãnh đạo vụ phân công',
        },
        {
          role: 'Chuyên viên xử lý',
          job: 'Thẩm định hồ sơ',
        },
        {
          role: 'Lãnh đạo phòng THCS',
          job: 'Xem xét hồ sơ',
        },
        {
          role: 'Lãnh đạo cục PTDN',
          job: 'Xemn xét hồ sơ',
        },
        {
          role: 'Lãnh đạo Bộ',
          job: 'Phê duyệt giấy phép',
        },
        {
          role: 'Xác nhận đóng dấu',
          job: 'Văn phòng Bộ',
        },
        {
          role: 'Bộ phận một cửa',
          job: 'Trả kết quả',
        },
      ],
      columns: [
        {
          field: '',
          key: 'STT',
          title: 'STT',
          align: 'center',
          width: 50,
          renderBodyCell: ({ rowIndex }) => rowIndex + 1,
        },
        {
          field: 'role',
          key: 'role',
          title: 'Vai trò',
          align: 'left',
          width: 250,
        },
        {
          field: 'job',
          key: 'job',
          title: 'Công việc',
          align: 'left',
        },
        {
          field: 'date',
          key: 'date',
          title: 'Thời hạn quy định',
          align: 'left',
          width: 150,
        },
        {
          field: 'dateStart',
          key: 'dateStart',
          title: 'Ngày bắt đầu',
          align: 'left',
          width: 150,
        },
        {
          field: 'people',
          key: 'people',
          title: 'Người thực hiện',
          align: 'left',
          width: 150,
        },
        {
          field: 'result',
          key: 'result',
          title: 'Kết quả',
          align: 'left',
          width: 150,
        },
      ],
    }
  },
}
</script>
