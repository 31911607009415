<template>
  <div class="card-body">
    <div>
      <div class="d-flex align-items-center">
        <img
          alt=""
          src="../assets/images/Vector.svg"
        >
        <h5 class="color-text">
          Thông tin hồ sơ
        </h5>
      </div>
      <hr class="color-hr">
    </div>
    <div class="custom-grid-xl-container mb-1">
      <div
        v-for="item in listTTHS"
        :key="item.id"
        :class="item.class"
        class="mb-1"
      >
        <span v-if="item && item.name">{{ item.name }}: </span>
        <b v-if="item.id === 'tenThuTucHanhChinh'">{{ data && data[item.field] }}</b>
        <span v-else-if="item.id === 'phiHs'">{{ data && formatPhi(data[item.field]) }} <span v-if="data.phiHs_DaNop">(Đã nộp)</span></span>
        <span v-else-if="item.id === 'lePhi'">{{ data && formatPhi(data[item.field]) }} <span v-if="data.lePhi_DaNop">(Đã nộp)</span></span>
        <span v-else>{{ data && data[item.field] }}</span>
      </div>
    </div>
    <div>
      <div class="d-flex align-items-center">
        <img
          alt=""
          src="../assets/images/Vector.svg"
        >
        <h5 class="color-text">
          Thông tin người nộp hồ sơ
        </h5>
      </div>
      <hr class="color-hr">
    </div>
    <div class="custom-grid-xl-container mb-1">
      <div
        v-for="item in listChuHoSo"
        :key="item.id"
        :class="item.class"
        class="mb-1"
      >
        <span v-if="item && item.name">{{ item.name }}: </span>
        <b v-if="item.id === 'tenThuTucHanhChinh'">{{ data && data[item.field] }}</b>
        <span v-else-if="item.id === 'phiHs'">{{ data && formatPhi(data[item.field]) }} <span v-if="data.phiHs_DaNop">(Đã nộp)</span></span>
        <span v-else-if="item.id === 'lePhi'">{{ data && formatPhi(data[item.field]) }} <span v-if="data.lePhi_DaNop">(Đã nộp)</span></span>
        <span v-else>{{ data && data[item.field] }}</span>
      </div>
    </div>
    <slot
      name="custom-form"
      class="mb-1"
    />
    <div>
      <div class="d-flex align-items-center">
        <img
          alt=""
          src="../assets/images/Vector.svg"
        >
        <h5 class="color-text">
          Thông tin thành phần hồ sơ
        </h5>
      </div>
      <hr class="color-hr">
    </div>
    <div
      v-if="rowhs.length > 0"
      class="mb-2"
    >
      <good-table
        ref="vgTable"
        class="mb-1"
        :columns="columns"
        :rows="rowhs"
        :is-loading="false"
        :pagination="false"
        :selectable="false"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <span v-if="props.props.column.field == 'dsHoSoGiayToFileDinhKem'">
            <div
              v-for="(item, index) in props.props.row.dsHoSoGiayToFileDinhKem"
              :key="`${item.tenFileDinhKem}_${index}_ghs`"
            >
              - <a
                href="javascript:;"
                @click="downloadFile(item)"
              >{{ item.tenFileDinhKem }}</a>
            </div>
          </span>
        </template>
      </good-table>
    </div>
    <div
      v-else
      class="mb-1"
    >
      Không có
    </div>
    <div>
      <div class="d-flex align-items-center">
        <img
          alt=""
          src="../assets/images/Vector.svg"
        >
        <h5 class="color-text">
          Thông tin giấy tờ khác
        </h5>
      </div>
      <hr class="color-hr">
    </div>
    <div
      v-if="rowgt.length > 0"
      class="mb-2"
    >
      <good-table
        ref="vgTable"
        class="mb-1"
        :columns="columnsGtk"
        :rows="rowgt"
        :is-loading="false"
        :pagination="false"
        :selectable="false"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <span v-if="props.props.column.field == 'dsHoSoGiayToFileDinhKem'">
            <div
              v-for="(item, index) in props.props.row.dsHoSoGiayToFileDinhKem"
              :key="`${item.tenFileDinhKem}_${index}_gtk`"
            >
              - <a
                href="javascript:;"
                @click="downloadFile(item)"
              >{{ item.tenFileDinhKem }}</a>
            </div>
          </span>
        </template>
      </good-table>
    </div>
    <div
      v-else
      class="mb-1"
    >
      Không có
    </div>
    <div>
      <div class="d-flex align-items-center">
        <img
          alt=""
          src="../assets/images/Vector.svg"
        >
        <h5 class="color-text">
          Thông tin giấy tờ tiếp nhận bổ sung
        </h5>
      </div>
      <hr class="color-hr">
    </div>
    <div
      v-if="rowgtk.length > 0"
      class="mb-2"
    >
      <good-table
        ref="vgTable"
        class="mb-1"
        :columns="columns"
        :rows="giayToBoSung"
        :is-loading="false"
        :pagination="false"
        :selectable="false"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <span v-if="props.props.column.field == 'dsHoSoGiayToFileDinhKem'">
            <div
              v-for="(item, index) in props.props.row.dsHoSoGiayToFileDinhKem"
              :key="`${item.tenFileDinhKem}_${index}_gtk`"
            >
              - <a
                href="javascript:;"
                @click="downloadFile(item)"
              >{{ item.tenFileDinhKem }}</a>
            </div>
          </span>
        </template>
      </good-table>
    </div>
    <div
      v-else
      class="mb-1"
    >
      Không có
    </div>
    <h4 v-if="rowgKQ.length > 0">
      Thông tin kết quả
    </h4>
    <div
      v-if="rowgKQ.length > 0"
      class="mb-2"
    >
      <good-table
        ref="vgTable"
        class="mb-1"
        :columns="columnsKQ"
        :rows="rowgKQ"
        :is-loading="false"
        :pagination="false"
        :selectable="false"
      >
        <template
          slot="custom-row"
          slot-scope="props"
        >
          <span v-if="props.props.column.field == 'dsHoSoGiayToFileDinhKem'">
            <a
              href="javascript:;"
              @click="downloadFilePath(props.props.row)"
            >{{ props.props.row.tenFile }}</a>
          </span>
        </template>
      </good-table>
    </div>
    <div class="d-sm-flex justify-content-end">
      <slot name="actionsTths" />
      <button
        v-if="(nameRouter === ROUTE_NAME.BAN_GIAO_HS.DS || nameRouter === 'tiep-nhan-ho-so-truc-tiep') && checkQuyenSua"
        class="compact ui btn-primary button"
        @click="editHoSo"
      >
        Sửa hồ sơ
      </button>
      <button
        v-if="(nameRouter === ROUTE_NAME.BAN_GIAO_HS.DS || nameRouter === 'tiep-nhan-ho-so-truc-tiep') && checkQuyenXoa"
        class="compact ui btn-primary button"
        @click="destroyHoSo"
      >
        Hủy hồ sơ
      </button>
      <button
        class="compact ui btn-danger button"
        @click="closePage"
      >
        Đóng
      </button>
    </div>
    <commonModal
      ref="commonModal"
      :title="title"
      size="sm"
      @handle-ok="handleOk"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import END_POINTS from '@/api/endpoints'
import addCssSemantic from '@/mixins/mixins'
import GoodTable from '@/components/GoodTable.vue'
import * as dayjs from 'dayjs'
import commonModal from '@/modules/xu-ly-ho-so/components/modal/CommonModal.vue'
import { ROUTE_NAME } from '@/modules/xu-ly-ho-so/constants/constants'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'

export default {
  name: 'ChiTietHoSo',
  components: {
    GoodTable,
    commonModal,
  },
  mixins: [addCssSemantic],
  props: {
    thongTinHoSo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ROUTE_NAME,
      title: 'Xác nhận',
      listChuHoSo: [
        {
          id: 'nguoiNop_HoVaTen',
          name: 'Người nộp',
          field: 'nguoiNop_HoVaTen',
          class: 'grap-4',
        },
        {
          id: 'chuHoSo_HoVaTen',
          name: 'Chủ hồ sơ',
          field: 'chuHoSo_HoVaTen',
          class: 'grap-4',
        },
        {
          id: 'emnty_1',
          name: '',
          field: '',
          class: 'grap-4 d-none d-lg-block',
        },
        {
          id: 'nguoiNop_CMND_HoChieu',
          name: 'Số CMND/Hộ chiếu',
          field: 'nguoiNop_CMND_HoChieu',
          class: 'grap-4',
        },
        {
          id: 'chuHoSo_CMND_HoChieu',
          name: 'Số CMND/Hộ chiếu',
          field: 'chuHoSo_CMND_HoChieu',
          class: 'grap-4',
        },
        {
          id: 'emnty_2',
          name: '',
          field: '',
          class: 'grap-4 d-none d-lg-block',
        },
        {
          id: 'nguoiNop_DiaChiDayDu',
          name: 'Địa chỉ',
          field: 'nguoiNop_DiaChiDayDu',
          class: 'grap-4 word-break',
        },
        {
          id: 'chuHoSo_DiaChiDayDu',
          name: 'Địa chỉ',
          field: 'chuHoSo_DiaChiDayDu',
          class: 'grap-4 word-break',
        },
        {
          id: 'emnty_3',
          name: '',
          field: '',
          class: 'grap-4 d-none d-lg-block',
        },
        {
          id: 'nguoiNop_DiaChiEmail',
          name: 'Email',
          field: 'nguoiNop_DiaChiEmail',
          class: 'grap-4',
        },
        {
          id: 'chuHoSo_DiaChiEmail',
          name: 'Email',
          field: 'chuHoSo_DiaChiEmail',
          class: 'grap-4',
        },
        {
          id: 'emnty_4',
          name: '',
          field: '',
          class: 'grap-4 d-none d-lg-block',
        },
        {
          id: 'nguoiNop_SoDienThoai',
          name: 'Điện thoại',
          field: 'nguoiNop_SoDienThoai',
          class: 'grap-4',
        },
        {
          id: 'chuHoSo_SoDienThoai',
          name: 'Điện thoại',
          field: 'chuHoSo_SoDienThoai',
          class: 'grap-4',
        },
        {
          id: 'emnty_5',
          name: '',
          field: '',
          class: 'grap-4 d-none d-lg-block',
        },
      ],
      listTTHS: [
        {
          id: 'maBienNhan',
          name: 'Mã biên nhận',
          field: 'code',
          class: 'grap-12',
        },
        {
          id: 'tenLinhVuc',
          name: 'Tên lĩnh vực',
          field: 'tenLinhVuc',
          class: 'grap-4',
        },
        {
          id: 'phiHs',
          name: 'Phí',
          field: 'phiHs',
          class: 'grap-4',
        },
        {
          id: 'lePhi',
          name: 'Lệ phí',
          field: 'lePhi',
          class: 'grap-4',
        },
        {
          id: 'tenThuTucHanhChinh',
          name: 'Tên thủ tục hành chính',
          field: 'tenThuTucHanhChinh',
          class: 'grap-12',
        },
        {
          id: 'tenMauHoSo',
          name: 'Tên mẫu hồ sơ',
          field: 'tenMauHoSo',
          class: 'grap-4',
        },
        {
          id: 'thoiGianDenHanTraKQ',
          name: 'Thời gian giải quyết',
          field: 'thoiGianDenHanTraKQ',
          class: 'grap-4 d-none d-lg-block',
        },
        {
          id: 'emnty_2',
          name: '',
          field: '',
          class: 'grap-4 d-none d-lg-block',
        },
        {
          id: 'ngayNop',
          name: 'Ngày nộp',
          field: 'ngayNop',
          class: 'grap-4',
        },
        {
          id: 'ngayTiepNhan',
          name: 'Ngày tiếp nhận',
          field: 'ngayTiepNhan',
          class: 'grap-4',
        },
        {
          id: 'ngayHenTraKq',
          name: 'Ngày hẹn trả',
          field: 'ngayHenTraKq',
          class: 'grap-4 d-none d-lg-block',
        },
      ],
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên giấy tờ',
          field: 'tenGiayTo',
          width: '300px',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Bản chính',
          field: 'banChinh',
          width: '150px',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Bản sao',
          field: 'banSao',
          tdClass: 'text-center',
          thClass: 'text-center',
          width: '100px',
          sortable: false,
        },
        {
          label: 'Bản photo',
          field: 'banPhoto',
          tdClass: 'text-center',
          thClass: 'text-center',
          width: '150px',
          sortable: false,
        },
        {
          label: 'File đính kèm',
          field: 'dsHoSoGiayToFileDinhKem',
          thClass: 'text-center',
          sortable: false,
        },
      ],
      columnsGtk: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên giấy tờ',
          field: 'tenGiayTo',
          width: '300px',
          thClass: 'text-center',
          sortable: false,
        },
        {
          label: 'File đính kèm',
          field: 'dsHoSoGiayToFileDinhKem',
          thClass: 'text-center',
          sortable: false,
        },
      ],
      columnsKQ: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'File đính kèm',
          field: 'dsHoSoGiayToFileDinhKem',
          thClass: 'text-center',
          sortable: false,
        },
      ],
      data: [],
      rowhs: [],
      rowgt: [],
      rowgtk: [],
      nameRouter: null,
      rowgKQ: [],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'common/permissions',
    }),
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME[this.$parent.permissionName].SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME[this.$parent.permissionName].XOA])
    },
    giayToBoSung() {
      return this.sortArray(this.rowgtk, 'thuTu')
    },
  },
  watch: {
    thongTinHoSo() {
      this.detail = this.thongTinHoSo
      this.data = {
        code: this.detail.code,
        ngayNop: this.detail.isTrucTuyen ? this.formatDate(this.detail.ngayNop) : this.formatDate(this.detail.ngayTiepNhan),
        ngayTiepNhan: this.formatDate(this.detail.ngayTiepNhan),
        nguoiNop_HoVaTen: this.detail.nguoiNop_HoVaTen,
        chuHoSo_HoVaTen: this.detail.chuHoSo_HoVaTen,
        thoiGianDenHanTraKQ: this.detail.soNgayXuLy,
        ngayHenTraKq: this.formatDate(this.detail.ngayHenTraKq),
        nguoiNop_DiaChiDayDu: this.detail.nguoiNop_DiaChiDayDu,
        chuHoSo_DiaChiDayDu: this.detail.chuHoSo_DiaChiDayDu,
        chuHoSo_DiaChiEmail: this.detail.chuHoSo_DiaChiEmail,
        nguoiNop_DiaChiEmail: this.detail.nguoiNop_DiaChiEmail,
        chuHoSo_SoDienThoai: this.detail.chuHoSo_SoDienThoai,
        nguoiNop_SoDienThoai: this.detail.nguoiNop_SoDienThoai,
        chuHoSo_CMND_HoChieu: this.detail.chuHoSo_CMND_HoChieu,
        nguoiNop_CMND_HoChieu: this.detail.nguoiNop_CMND_HoChieu,
        tenThuTucHanhChinh: this.detail.tenThuTucHanhChinh,
        tenMauHoSo: this.detail.tenMauHoSo,
        tenLinhVuc: this.detail.tenLinhVuc,
        lePhi: this.detail.lePhi,
        lePhi_DaNop: this.detail.lePhi_DaNop,
        phiHs: this.detail.phiHs,
        phiHs_DaNop: this.detail.phiHs_DaNop,
      }
      this.rowhs = this.detail.hoSoGiayToModels
      this.rowgt = this.detail.hoSoGiayToKhacModels
      this.rowgtk = this.detail.hoSo_GiayToBoSungModels
      this.rowgKQ = this.detail.tepKetQuaHoSo
    },
  },
  methods: {
    sortArray(arr, key) {
      return arr.sort((a, b) => {
        if (a[key] < b[key]) return -1
        if (a[key] > b[key]) return 1
        return 0
      })
    },
    downloadFile(data) {
      this.$axios.post(END_POINTS.UPLOAD_FILE.DOWNLOAD, { path: data.pathFileDinhKiem }).then(res => {
        if (res.data?.succeeded) {
          // eslint-disable-next-line prefer-const
          let a = document.createElement('a')
          a.href = `data:application/octet-stream;base64,${res.data.fileData}`
          a.download = res.data.fileName
          a.setAttribute('target', '_blank')
          a.click()
        }
      })
    },
    downloadFilePath(data) {
      this.$axios.post(END_POINTS.UPLOAD_FILE.DOWNLOAD, { path: data.path }).then(res => {
        if (res.data?.succeeded) {
          // eslint-disable-next-line prefer-const
          let a = document.createElement('a')
          a.href = `data:application/octet-stream;base64,${res.data.fileData}`
          a.download = res.data.fileName
          a.setAttribute('target', '_blank')
          a.click()
        }
      })
    },
    closePage() {
      this.$emit('close-page')
    },
    formatDate(date) {
      return date ? dayjs(date).format('HH:mm - DD/MM/YYYY') : null
    },
    differenceInMinutes(start, end) {
      const totalMinutes = value => {
        const match = (/(\d{1,2}):(\d{1,2})/g).exec(value)
        return (Number(match[1]) * 60) + Number(match[2])
      }
      return totalMinutes(end) - totalMinutes(start)
    },
    convertMinsToHrsMins(minutes) {
      let h = Math.floor(minutes / 60)
      let m = minutes % 60
      h = h < 10 ? `0${h}` : h
      m = m < 10 ? `0${m}` : m
      return `${h}:${m}`
    },
    editHoSo() {
      if (this.nameRouter === ROUTE_NAME.BAN_GIAO_HS.DS) {
        this.$router.push({ path: `${ROUTE_NAME.NAME}${ROUTE_NAME.BAN_GIAO_HS.NAME}/${ROUTE_NAME.BAN_GIAO_HS.SUA}/${this.hoSoId}` })
      } else if (this.nameRouter === 'tiep-nhan-ho-so-truc-tiep') {
        this.$router.push({ path: `/tiep-nhan-ho-so/truc-tiep/cap-nhat/${this.hoSoId}` })
      }
    },
    destroyHoSo() {
      this.$refs.commonModal.showModal()
    },
    handleOk() {
      const payload = {
        listId: [this.hoSoId],
      }
      this.$axios.delete(END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.XOA, payload).then(res => {
        if (res.data?.succeeded) {
          this.closePage()
        }
      })
    },
    formatPhi(num) {
      if (num) {
        return num.toString().replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
      return 0
    },
  },
}
</script>
<style lang="scss" scoped>
 .color-hr{
    border-top: 1px solid #005DB4 !important;
  }
  .color-text{
    color: #005DB4 !important;
    margin-left: 12px;
    font-size: 16px;
  }
  h5{ margin-bottom: 0rem !important;}
  img {
    width: 18px;
    margin-bottom: 4px;
  }
  .text-weight{
    font-weight:700;
  }
</style>
