<template>
  <div>
    <b-card no-body>
      <b-tabs>
        <b-tab
          title="THÔNG TIN HỒ SƠ"
          active
        >
          <ThongTinHoSo />
        </b-tab>
        <b-tab title="XƯ LÝ HỒ SƠ">
          <XuLyHoSo />
        </b-tab>
        <b-tab title="TIẾN TRÌNH XỬ LÝ">
          <TienTrinhXuLy />
        </b-tab>
        <b-tab title="GIA HẠN HỒ SƠ">
          <GiaHanHoSo />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
} from 'bootstrap-vue'

import ThongTinHoSo from './ChiTietHoSo/ThongTinHoSo.vue'
import XuLyHoSo from './ChiTietHoSo/XuLyHoSo.vue'
import TienTrinhXuLy from './ChiTietHoSo/TienTrinhXuLy.vue'
import GiaHanHoSo from './ChiTietHoSo/GiaHanHoSo.vue'

export default {
  name: 'ChiTietHoSo',
  components: {
    BCard,
    BTabs,
    BTab,
    ThongTinHoSo,
    XuLyHoSo,
    TienTrinhXuLy,
    GiaHanHoSo,
  },
}
</script>

<style lang="scss" scoped>

::v-deep .nav-tabs {
background-color:#043F6F !important ;
.nav-link{
    font-size: 20px !important;
    font-weight: 500;
    font: Montserrat;
    line-height: 24.38px;
}
}

</style>
